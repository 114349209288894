.links {
  margin-bottom: 10px;

  @include rm(640){
    width: 100%;
    display: flex;
    gap: 30px;
    justify-content: center;
  }

  @include rm(768){
    order: 1;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 0;
    border: none;
    display: flex;
  }

  @include rm(1024){
    width: auto;
    gap: 10px;
  }
}

.links.active {
  cursor: default;
}

.links__link {
  position: relative;
  transition: all 0.5s;
  font-weight: $links-weight;
  width: 100%;
  max-width: 244px;
  font-size: 18px;
  text-align: center;
  display: block;
  color: $links-color;
  margin: 0 auto;
  padding: 10px;

  @include rm(768) {
    font-size: 14px;
  }

  @include rm(1024) {
    cursor: pointer;
  }
}

.links__link.active {
  cursor: default;
  color: $active-color;
}

.links__title {
  position: relative;
  font-weight: 400;
  width: 100%;
  max-width: 244px;
  font-size: 18px;
  text-align: center;
  display: block;
  color: #171219;
  margin: 0 auto;
  padding: 10px;
}

@include links;
