.breadcrumbs {
  padding-top: 15px;
  padding-bottom: 15px;
}

.breadcrumbs .container {
  display: flex;
  flex-direction: column;

  @include rm(768){
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.breadcrumbs-list {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  @include rm(640) {
    gap: 8px;
  }
}

.breadcrumbs-item {
  position: relative;

  &:not(:first-child){
    padding-left: 15px;

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      width: 10px;
      height: 10px;
      background: url($breadcrumbs-icon) no-repeat;
      background-size: 100%;
      transform: translateY(-50%);
    }
  }

  @include rm(1024){
    cursor: pointer;
  }
}

.breadcrumbs-item__link {
  display: block;
  font-size: 13px;
  line-height: 1.2;
  color: $grey-light;

  @include rm(540) {
    font-size: 14px;
  }
}

.breadcrumbs-item__link.active {
  color: $active-color;
  cursor: default;
  text-transform: capitalize;
}

.breadcrumbs-page {
  display: none;

  @include rm(768) {
      display: flex;
      flex-shrink: 0;
  }
}

.breadcrumbs-page__link {
  color: #7F7F7F;
  font-size: 14px;
  font-weight: 700;
  flex-shrink: 0;
}

@include breadcrumbs;
