
.text-tooltip {
  position: relative;
}

.text-tooltip__label {
  font-size: 14px;
  cursor: pointer;
}

.text-tooltip__content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
}

.text-tooltip__label:hover ~ .text-tooltip__content {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 540px) {
  .text-tooltip__label:focus-within ~ .text-tooltip__content {
      opacity: 1;
      visibility: visible;
  }
}

@include text-tooltip;