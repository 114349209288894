.catalog {
  padding-top: 20px;
  padding-bottom: 40px;
}

.update-date {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  color: #171219;
  font-size: 16px;
  font-weight: 400;

  &__img {
    margin-right: 8px;

    & path {
      stroke: $decorative-color;
    }
  }

  @include rm(1024) {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  @include rm(768) {
    justify-content: flex-end;
    margin-bottom: 12px;
  }
}

.catalog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  counter-reset: section;
  gap: 15px;

  @include rm(640){
    align-items: stretch;
  }

  @include rm(1024){
    gap: 20px;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}

.catalog-item__title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 15px;

  @include rm(1024){
    max-width: 260px;
    margin-right: 80px;
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
  }
}


.catalog-item__rating {
  text-align: center;
  font-size: 24px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.catalog-item__rating--mob {
  @include rm(1024){
    display: none;
  }
}
.catalog-item__rating--desk {
  display: none;
  @include rm(1024){
    display: flex;
  }
}

.catalog-item__advanteges {
  position: relative;
  font-size: 14px;
  padding-left: 15px;
  color: $black;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    width: 10px;
    height: 10px;
    background: url($breadcrumbs-icon) no-repeat;
    background-size: 100%;
    transform: translateY(-50%);
  }

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
}

.catalog-item__more {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  color: $primary-button-color;
  margin-top: 15px;
  margin-bottom: 20px;

  @include rm(1024){
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.catalog-item {
  display: flex;
  flex-direction: column;
  max-width: 320px;
  width: 100%;
  padding: 25px 20px;
  position: relative;
  counter-increment: section;
  background: $panel-item-color;
  box-shadow: 0 4px 16px rgba(23, 18, 25, 0.1);

  &__bottom {
    margin-top: auto;

    @include rm(1024) {
      margin-top: unset;
    }
  }

  &:before {
    content: counter(section) '';
    padding: 10px;
    top: 0;
    left: 0;
    width: 38px;
    height: 47px;
    text-align: center;
    position: absolute;
    font-size: 16px;
    line-height: 1;
    color: white;
    z-index: 2;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 80%, 0 100%);
    background-color: $product-flag;
  }

  @include rm(640){
    max-width: 280px;
  }

  @include rm(768){
    max-width: 330px;
    width: 100%;
  }

  @include rm(1024){
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    width: 100%;

    padding: 25px 40px 25px 55px;
  }
}

.catalog-item-cursor {
  cursor: pointer;
}

.catalog-item__title img {
  margin: 0 auto;
  max-width: 170px;
  width: 100%;

  @include rm(1024){
    margin: 0;
  }
}

.catalog-item__title span {
  display: none;

  @include rm(1024){
    display: block;
    text-align: left;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 10px;
  }
}
.catalog-item__visit {
  text-transform: $btn-text-transform;
  max-width: 280px;
  width: 100%;
  margin: 0 auto;

  @include rm(1024){
    margin: 0;
    max-width: 210px;
  }
}

.catalog-item__bottom {
  @include rm(1024){
    width: 100%;
    max-width: 215px;
    margin-left: auto;
  }
}

.catalog__question {
  @include rm(1024){
    cursor: pointer;
  }
}

.catalog__tooltip {
  width: 18px;
  height: 18px;
}

.catalog-item--popular {
  position: relative;
  border: 2px solid $decorative-color;
  padding-top: 50px;

  &:before {
    background-color: $decorative-color;
  }

  &:after {
    position: absolute;
    content: "🔥 Popular Choice 🔥";
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    top: -23px;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 8px;
    text-align: center;
    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 52% 90%, 50% 100%, 48% 90%, 0 90%);
    background-color: $decorative-color;
    line-height: 1.2;
    font-size: 16px;
    width: 188px;
    height: 48px;
  }

  @include rm(1024){
    &:after {
      transform: none;
      left: 55px;
    }
    padding-top: 25px;
  }
}

.catalog-item--popular-background {
  background-color: rgb(222 110 87 / 24%);
}

.catalog-item--popular-asian {
  &:after {
    content: "🔥 Senior Dating";
  }
}

.catalog-item--popular-slavic {
  &:after {
    content: "🔥 Adventure Seekers";
  }
}
.catalog-item--popular-latina {
  &:after {
    content: "🔥 Niche Dating";
  }
}
.catalog-item__advanteges--none {
  display: none;
  @include rm(1024){
    display: block;
  }
}

@include catalog;
