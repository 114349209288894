.rate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  @include rm(768){
    margin-bottom: 0px;
    order: 2;
    flex-direction: row;
  }

  @include rm(1024){
    flex-direction: row;
  }
}

.rate-text {
  margin-bottom: 15px;

  @include rm(768){
    font-size: 14px;
    margin-bottom: 0px;
    white-space: nowrap;
    margin-right: 5px;
  }
}
.rate-btn {
  text-align: center;
  max-width: 310px;
  width: 100%;
  font-weight: bold;
  background: $primary-color;
  padding: 16px 18px;
  color: white;
  text-transform: uppercase;
  font-size: 16px;

  &:hover, &:active {
    text-decoration: underline;
  }

  @include rm(360){
    padding: 16px 30px;
  }

  @include rm(768){
    padding: 0;
    color: $black;
    background: transparent;
  }

  @include rm(1024){
    cursor: pointer;
    position: relative;
    padding-left: 15px;

    &:before {
      position: absolute;
      content: "";
      top: 50%;
      left: 0;
      width: 10px;
      height: 10px;
      background: url($breadcrumbs-icon) no-repeat;
      background-size: 100%;
      transform: translateY(-50%);
    }
  }

}