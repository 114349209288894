.rate-block {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
}

.rate-block h2 {
  text-transform: uppercase;
  font-size: 20px;
}

.title-some {
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
}

.rate-slider {
  padding-bottom: 80px;
}

.rate-slider__item {
  display: block;
  margin-right: 5px;
  margin-left: 5px;
  @include rm(640) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.rate-slider .slick-arrow {
  display: none;
}

.rate-slider .slick-list {

  padding-top: 20px;

  @include rm(1024) {
    padding: 20px 30% 0 0;
  }
  @include rm(1220) {
    padding: 20px 23% 0 0;
  }

  @include rm(1440) {
    padding: 20px 33% 0 0;
  }

}

.rate-slider .product-banner--mob {
  @include rm(640) {
    display: none;
  }
}

.rate-slider .product-banner--desk {
  @include rm(640) {
    display: block;
  }
}

.rate-slider .product-banner__rating {
  margin-bottom: 24px;
  @include rm(640) {
    margin-bottom: 24px;
  }
}

.rate-slider .product-banner__info {
  margin-bottom: 24px;
  @include rm(640) {
    margin-bottom: 24px;
  }
}

.rate-slider .product-banner--popular {
  padding-top: 40px;
}

.rate-slider .product-banner__title {
  align-items: center;
  height: 35px;
}


.rate-slider .product-banner {
  box-shadow: none;

  @include rm(640) {
    display: block;
    max-width: 320px;
  }
}

.product-banner--mob {
  display: none;
  @include rm(640) {
    display: block;
  }
  @include rm(1024) {
    display: none;
  }
}

.rate-slider__item--1 .product-banner:before {
  content: '1';
}

.rate-slider__item--2 .product-banner:before {
  content: '2';
}

.rate-slider__item--3 .product-banner:before {
  content: '3';
}

.rate-slider__item--4 .product-banner:before {
  content: '4';
}

.rate-slider__item--5 .product-banner:before {
  content: '5';
}

.rate-slider__item--6 .product-banner:before {
  content: '6';
}

.rate-slider__item--7 .product-banner:before {
  content: '7';
}

.rate-slider__item--8 .product-banner:before {
  content: '8';
}

.rate-slider__item--9 .product-banner:before {
  content: '9';
}

.rate-slider__item--10 .product-banner:before {
  content: '10';
}

.rate-slider__item--11 .product-banner:before {
  content: '11';
}

.rate-slider__item--12 .product-banner:before {
  content: '12';
}

.rate-slider__item--13 .product-banner:before {
  content: '13';
}

.rate-slider__item--14 .product-banner:before {
  content: '14';
}


.rate-girls--bg {
  overflow: hidden;
  padding: 50px 0px 0px 0px;
  background-color: #97C9E8;


  @include rm(640) {
    padding: 50px 0 0 0;
  }

  @include rm(1024) {
    padding-top: 50px;
  }
}

.rate__title {
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: white;
  margin-bottom: 20px;
}

.rate-girls {
  position: relative;

  &:after {
    position: absolute;
    content: "💬 Get into the chat";
    color: #fff;
    top: -15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 14px 0;
    text-align: center;
    background: url(../img/popular-bg.svg) no-repeat;
    background-position: center;
    line-height: 1.2;
    font-size: 16px;
    width: 188px;
    height: 63px;
    z-index: 2;
  }
}


.rate-girls__info {
  display: none;
  width: 100%;
  position: absolute;
  bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  @include rm(480){
    padding-left: 30px;
    padding-right: 30px;
    bottom: 30px;
  }
}

.rate-girls__product {
  font-size: 18px;
  color: white;
  text-shadow: 0px 0px 6px rgba(66, 68, 90, 1);
}

.rate-girls__name {
  font-size: 16px;
  display: block;

  text-transform: uppercase;
  color: white;
  font-weight: 700;
  margin-bottom: 10px;
  text-shadow: 0px 0px 6px rgba(66, 68, 90, 1);

  @include rm(480){
    font-size: 18px;
  }

  @include rm(640){
    font-size: 24px;
  }

}


.rate-girls__dislike,
.rate-girls__like {
  cursor: pointer;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #FF9518;
  width: 50px;
  height: 50px;

  @include rm(480){
    width: 76px;
    height: 76px;
  }

}


.rate-girls__dislike {
  background: #333333;
  margin-right: 10px;

  @include rm(480){
    margin-right: 20px;
  }
}

.rate-girls__right {
  display: flex;
}

.rate-girls__item.slick-slide.rate-prev {
  transform: translate(180px, 50px);
}

.rate-girls__item.slick-slide.rate-prev2 {
  transform: translate(480px, 85px);
}

.rate-girls__item.slick-slide.rate-prev3 {
  transform: translate(900px, 85px);
}

.rate-girls__item.slick-slide.rate-next {
  transform: translate(-180px, 50px);
}

.rate-girls__item.slick-slide.rate-next2 {
  position: relative;
  z-index: -2;
  transform: translate(-450px,85px);
}

.rate-girls__item.slick-slide.rate-next3 {
  transform: translate(-500px,135px);
  z-index: -3;
}



.rate-girls__slider .slick-slide:after {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(81, 34, 93, 0.97);
}

.rate-girls__slider .slick-current.slick-slide:after {
  background: transparent;
  z-index: -1;
}


//.rate-girls__item img {
//  max-width: 100%;
//  width: 100%;
//  height: auto;
//}

.rate-girls__item {
  display: block;
  position: relative;
  max-width: 250px;
  height: 297px;
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  @include rm(375){
    max-width: 275px;
    height: 325px;
  }

  @include rm(480){
    max-width: 405px;
    height: 420px;
  }


}

.rate-girls__slider .slick-track {
  display: flex;
  align-items: flex-end;
}

.rate-girls__slider .slick-current {
  transition: all .9s;
  position: relative;
  width: 100%;
  opacity: 1;
  z-index: 5;

  & .rate-girls__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.rate-wrapper {
  @include rm(1024){
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}

.pulse {
  animation: pulse-animation 5s infinite;
}

@keyframes pulse-animation {
  50% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
  }

  70% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.4);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.slick-current.active {
  background: red;
}


.rate-like__bg {
  display: none;
  height: 100%;
  width: 100%;
  background: rgba(81, 34, 93, 0.97);
  position: absolute;
  z-index: 6;
  padding: 25px 25px 10px;

  @include rm(480){
    padding: 50px 25px 10px;
  }
}

.rate-like__last {
  display: none;
  height: 100%;
  width: 100%;
  background: rgba(81, 34, 93, 0.97);
  position: absolute;
  z-index: 6;
  padding: 15px 15px 10px;

  @include rm(480){
    padding: 45px 25px 10px;
  }
}

.slick-current.active .rate-like__bg {
  display: flex;
}

.rate-like__chat {
  display: block;
  cursor: pointer;
  max-width: 320px;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  color: white;
  background: #97C9E8;
  text-transform: uppercase;
  padding: 18px;
  text-align: center;
  margin: 0 auto 15px;

  @include rm(480){
    font-size: 16px;
    margin: 0 auto 28px;
  }

}

.rate-like__chat--last {
  margin: 0 auto 0px;

  @include rm(480){
    margin: 0 auto 28px;
  }
}

.rate-like__back {
  cursor: pointer;
  display: block;
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}

.rate-like__img {
  margin: 0 auto 10px;
  max-width: 100px;
  width: 100%;

  @include rm(375){
    max-width: 115px;
  }
  @include rm(480){
    max-width: 130px;
    margin: 0 auto 30px;
  }
}


.rate-like__img--last {
  max-width: 150px;
  width: 100%;

  @include rm(375){
    max-width: 180px;
  }

  @include rm(480){
    max-width: 220px;
    margin-bottom: 20px;
  }

  @include rm(640){
    max-width: 260px;
  }
}


.rate-like__title {
  font-size: 14px;
  color: white;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 700;

  @include rm(480){
    font-size: 16px;
    margin-bottom: 20px;
  }
}


.rate-like__text {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-bottom: 15px;

  & span {
    font-weight: 700;
  }

  @include rm(480){
    font-size: 16px;
  }
}

.rate-like__logo {
  margin: 0 auto 5px;
}
