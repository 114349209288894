.checked {

  @include rm(375) {
    padding-top: 16px;
  }
}

.checked-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: $checked-list-justify;
  gap: 6px;

  @include rm(640){
    gap: 10px;
    max-width: 600px;
    width: 100%;
  }
  @include rm(1024){
    justify-content: flex-start;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
}

.checked-item {
  font-weight: bold;
  font-size: 14px;
  position: relative;
  padding-left: 24px;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: no-repeat center/12px 6px $decorative-color url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='4' viewBox='0 0 26 19' fill='none'%3E%3Cpath id='check-icon' fill-rule='evenodd' clip-rule='evenodd' d='M26 3.04019L10.3632 19L0 8.41927L3.01006 5.37934L10.3634 12.8853L22.9902 0L26 3.04019Z' fill='%23ffffff' /%3E%3C/svg%3E");
    background-size: 100%;
  }

  @include rm(640){
    padding-left: 30px;
    font-size: 16px;
    &:before {
      width: 24px;
      height: 24px;
    }
  }
}

@include checked;
