.pages {
  margin-bottom: 20px;

  @include rm(640){
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 20px;
    justify-content: center;
    margin-bottom: 30px;
  }
  @include rm(768) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    justify-content: flex-start;
    gap: 10px;
    order: 3;
  }
  @include rm(1024){
    margin: 0;
    gap: 30px;
    order: 0;
  }
}

.pages__link {
  max-width: $pages-link-width;
  width: 100%;
  font-size: 18px;
  text-align: center;
  display: block;
  padding: 10px;
  color: $black;
  margin: 0 auto;

  @include rm(768){
    color: $gray;
    font-size: 14px;
  }

  @include rm(1024) {
    cursor: pointer;
  }
}

.pages__wrapper {
  @include rm(768){
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 10px;
  }
}

.logo__link {
  @include rm(1024){
    width: 100%;
  }
  @include rm(1220){
    width: auto;
  }
}

.pages__item:last-child {
  font-weight: 700;
}

.pages-link {
  margin-bottom: 20px;

  @include rm(768) {
      display: none;
  }

  &__link {
      color: #000;
      font-size: 18px;
      font-weight: 700;
  }
}

@include pages-links;
