footer {
  background: $footer-bg;
  padding-bottom: 40px;
}

footer .logo {
  margin: 0 auto;
}
footer .rate {
  margin-bottom: 0;
}

.footer__logo {
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;

  &:before {
    position: absolute;
    content: "";
    width: 100vw;
    height: 1px;
    background: rgba(127,127,127,.5);
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  @include rm(768){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer__text {
  margin-bottom: 20px;
  text-align: center;

  @include rm(768){
    margin-bottom: 0;
    max-width: 320px;
    width: 100%;
  }

  @include rm(1024){
    max-width: 525px;
    text-align: left;
  }
}

.footer__wrapper {
  @include rm(768){
    display: flex;
    gap: 20px;
    //flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

footer .pages {
  @include rm(768){
    flex-direction: column;
    order: 0;
  }

  @include rm(1024){
    gap: 10px;
  }
}
footer .links__link,
footer .pages__link {
  color: $black;
  font-size: 16px;
  @include rm(768){
    font-size: 16px;
  }
}
footer .links {
  @include rm(768){
    max-width: 180px;
    width: 100%;
    flex-direction: column;
    order: 0;
  }

  @include rm(1024){
      gap: 10px;
  }
}

.footer__right {
  @include rm(768){
    max-width: 190px;
    width: 100%;
  }
}

.footer__rate-mob {
  @include rm(768){
    display: none;
  }
}


.footer__rate-desk {
  display: none;
  @include rm(768){
    display: block;
  }
}

.pages__link.active{
  text-decoration: underline;
}

@include footer;
