.header {
  padding-top: 15px;
  padding-bottom: 10px;
  background: white;
  z-index: 9;
  border-bottom: $header-border-bottom;

  @include rm(768){
    border-bottom: none;
  }
}

.header .container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

@include header;
