.popup-tooltip {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  background: #fff;
  height: auto;
  width: calc(100% - 30px);
  transform: translate(-50%, -50%);
  z-index: 20;
  padding: 15px;

  @include rm(480) {
    width: 400px;
    height: 280px;
  }
  @include rm(640) {
    padding: 20px;
    width: 500px;
    height: 280px;
  }
  @include rm(768) {
    padding: 25px;
    width: 600px;
    height: 320px;
  }
}

.popup-tooltip__close {
  width: 21px;
  height: 21px;
  margin-left: auto;
  margin-bottom: 15px;

  @include rm(480){
    margin-bottom: 25px;
  }
}

.popup-tooltip__close img {
  cursor: pointer;
  margin-left: auto;
}

.popup-tooltip__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.popup-tooltip__text {
  text-align: center;
  max-width: 350px;
  width: 100%;
  line-height: 25px;
  font-size: 16px;
}

.popup-tooltip__title {
  font-weight: 700;
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: #171219;
  margin-bottom: 10px;
}

.popup-tooltip__overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(23, 18, 25, 0.96); /* Black background with opacity */
  z-index: 15; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

