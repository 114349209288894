.post {
  display: block;
  box-shadow: 0 4px 16px rgba(23, 18, 25, 0.1);
  margin-bottom: 20px;
  max-width: 320px;
  width: 100%;
  border-radius: $post-item-radius;
  overflow: hidden;

  .post__wrapper,
  .post__img-wrap,
  .post__img path,
  .post__category,
  .post__date {
    transition: 0.5s;
  }

  &__img path {
    fill: $post-arrow-color;
  }

  &:hover {
    .post__wrapper {
      background: $post-wrapper-bg-hover;
      color: white;
    }

    .post__img path {
      fill: $post-arrow-color-hover;
    }

    .post__category {
      color: $post-category-color-hover;
    }

    .post__title {
      color: $post-title-hover;
      transition: .5s;
    }

    .post__date {
      color: white;
      opacity: 0.5;
    }

    .post__img-wrap {
      position: relative;

      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: transparent;
        z-index: 15;
        transition: .5s;
      }
    }
  }

  &:active {
    .post__wrapper {
      background: $post-wrapper-bg-active;
    }
  }

  @include rm(1024) {
    margin-bottom: 0;
  }
}

.post__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.post__date {
  color: #7F7F7F;
  font-size: 14px;
}

.post__category {
  color: $post-category-color;
  font-size: 14px;
  text-transform: uppercase;
}

.post__title {
  transition: .5s;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 700;
  text-transform: uppercase;
  color: $post-title;

  @include rm(1024) {
    &:hover {
      text-decoration: underline;
    }
  }
}


.post__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background: $post-wrapper-bg;
  min-height: 200px;
}

.post__link {
  width: 36px;
  display: block;
  margin-left: auto;

  @include rm(1024) {
    margin: 0;
  }
}

.post__img-wrap {
  width: 100%;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($post-img-color, .75);
    transition: .5s;
  }
}

@include posts;
