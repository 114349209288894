a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

br {
  font-size: 0;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

a,
a:focus,
button,
button:focus,
input,
input:focus {
  outline: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
}

::-moz-focus-inner,
::-moz-focus-outer {
  border: 0;
  padding: 0;
}

/**
 * Remove outlines in Firefox.
 */
select:-moz-focusring,
a:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

*:focus,
*:visited,
*:active,
*:hover {
  outline: 0 !important;
}

a,
a:focus,
a:visited,
a:active,
a:hover {
  outline: 0 !important;
}

*::-moz-focus-inner {
  border: 0;
  outline: none;
}
