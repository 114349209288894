.service__title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}


.service a {
  color: #0000EE;
}
.service ul li {
  margin-bottom: 10px;
}
.service ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}
.service p {
  margin-bottom: 20px;
}
.service {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
