.block {
  position: relative;
  padding-top: 30px;
  padding-bottom: 30px;


  @include rm(768) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include rm(1024) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include rm(1220) {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}
