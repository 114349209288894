.popup-quiz {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  display: none;
  transition: ease .5s;
}

.popup-quiz__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.popup-quiz__title {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 1.2;

  @include rm(480) {
    line-height: 1.3;
    //font-size: 18px;
  }
  @include rm(640) {
    font-size: 20px;
    margin-bottom: 20px;
  }

  @include rm(768) {
    font-size: 22px;
    margin-bottom: 30px;
  }

  @include rm(1024) {
    font-size: 24px;
    margin-bottom: 0px;
  }

  @include rm(1220) {
    font-size: 28px;
  }
}

.popup-quiz--bg {
  overflow: hidden;
  padding: 15px 10px 0;
  //margin-bottom: 10px;
  background-color: $primary-color;
  background-image: url($quiz-shadow-img);
  background-position: bottom center;
  background-repeat: no-repeat;

  @include rm(640) {
    background-position: top right;
  }
}

.popup-quiz__right {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;

  @include rm(480){
    margin-bottom: 20px;
  }
  @include rm(640){
    width: 100%;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 20px;
    align-self: center;
    padding-bottom: 10px;
  }

  @include rm(768){
    padding-bottom: 0;
  }

  @include rm(1024){
    max-width: 565px;
  }
}

.popup-quiz__right h3 {
  color: #FFF;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 12px;
  text-align: center;

  @include rm(375) {
    line-height: 1.3;
  }

  @include rm(640) {
    line-height: 1.6;
    font-size: 16px;
    margin-bottom: 30px;
  }

  @include rm(768) {
    font-size: 18px;
  }

  @include rm(1024) {
    font-size: 20px;
    margin-bottom: 40px;
    text-align: left;
  }
}

.popup-quiz__column {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: space-between;

  @include rm(640) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.popup-quiz__input {
  font-size: 14px;
  border: 1px solid #FFF;
  border-right: none;
  background: rgba(255, 255, 255, 0.10);
  width: 100%;
  max-width: 280px;
  padding: 12px 24px;

  font-weight: 700;
  text-transform: uppercase;

  &::placeholder {
    color: #FFF;
  }

  @include rm(480) {
    padding: 20px 24px;
  }
  @include rm(480) {
    padding: 18px 24px;
    font-size: 16px;
  }
}

.popup-quiz__form {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;

  @include rm(480){
    margin-bottom: 20px;
  }
  @include rm(640){
    margin-bottom: 30px;
  }

  @include rm(1024){
    justify-content: flex-start;
  }
}

.popup-quiz__button {
  cursor: pointer;
  position: relative;
  padding: 18px 24px;
  background: $secondary-button-color;

  color: #FFF;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: ">";
  }

  @include rm(768) {
    width: 100%;
    max-width: 130px;
    &:before {
      content: "SUBMIT";
    }
  }
  @include rm(1024) {
    max-width: 140px;
  }
}


.popup-quiz__close {
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 10px;

  @include rm(480){
    margin-right: 5px;
    margin-bottom: 15px;
  }
}

.popup-quiz__footnote {
  color: white;
  font-size: 12px;
  line-height: 1.2;

  @include rm(480){

    line-height: 1.6;
    text-align: center;
  }

  @include rm(640){
    font-size: 14px;
  }

  @include rm(1024){
    text-align: left;
  }
}

.popup-quiz__footnote a {
  color: white;
  text-decoration: underline;
}

.popup-quiz__img {
  position: relative;
  z-index: 2;
}

.popup-quiz__left {
  width: calc(100% + 40px);
  margin-left: -20px;

  @include rm(640){
    width: 100%;
    max-width: 310px;

    margin-left: -25px;
  }

  @include rm(1024){
    max-width: 370px;
    margin-left: -10px;
  }
}
.popup-quiz-img {
  max-width: 370px;
  width: 100%;
  margin: 0 auto;
}